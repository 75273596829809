
.custom-info-window{
    background-color:#005662 ;
    color:white !important;

}
.gm-style-iw {
    padding: unset !important;
    color:white !important;

}
.gm-style .gm-style-iw-c {
    background-color:#005662 ;
    width: 400px !important;
    overflow:hidden;
    height: 130px !important;
}
.gm-style-iw-d{
    overflow: hidden !important;
}

.gm-style .gm-style-iw-tc::after{
    display: none;
}
.gm-ui-hover-effect span{
    background: white !important;
}
.gm-ui-hover-effect {
    background: #005662 !important;
}

@media (max-width: 767px) {
    .gm-style .gm-style-iw-c {
        background-color:#005662 ;
        width: 400px !important;
        overflow:hidden;
        height: 300px !important;
    }
}
