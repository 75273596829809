.background-image-fill{
    background-size: cover;
    user-select: none;
    width: 100%;
    background-position: center;
}
.background-overlay-seats{
    display: grid;
    color:white;
    top: 0;
    left: 0;
    width: 100%;
    background-color:#00353DCC;
}
.select-seats{
    display: grid;
    height: 100%;
    justify-content: center;
    align-items: center;
}
.map-preview{
    margin-block: 70px;
    height: calc(100% - 140px);
    width: 100%;
    background-color: #f8f9fa;
    border: 20px solid #002b33;
}
.map-preview-mobile{
    background-color: #f8f9fa;
    width: 100%;
    border: 10px solid #002b33;
}
.booking-button{
    background-color: #31636b;
    color:white;
    /*font-weight: bold;*/
    padding:15px 15px;
    border-radius: 20px;
    font-size: 22px;
    cursor: pointer;
}
.booking-button-daypass{
    background-color: #31636b;
    color:white;
    /*font-weight: bold;*/
    padding:15px 15px;
    border-radius: 20px;
    font-size: 22px;
}
