.your-all-set{
    background: #b4d1d5;
}
.card-receipt{
    border-radius: 10px;
    background: #b4d1d5;
}
.card-receipt-items{
    background: #005662;
    color:white;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}
.card-beach-image-receipts{
    border-radius: 10px;
    height: 70px;
    width: 140px;
}
.all-set-title{
    color:#005662;
    font-size: 60px;
}

.all-set-text{
    color: #005662 ;
    /*font-weight: bold;*/
    font-size: 25px;
}
