.background-image {
    background-size: cover;
    background-repeat: no-repeat;
}

.overlay {
    min-height: 100vh;
    background-color: rgba(0, 53, 61, 0.63);
    display: grid;
    grid-template-rows: 2fr 1fr;
}

.overlay > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: large;
    letter-spacing: 2px;
}
.exit {
    margin-top: 30px;
    color: white;
    font-size: large;
}
.inputContainers {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
    margin: 15px;
}
.inputContainers :nth-child(1) {
    grid-column-start: 2;
}
input {
    height: min-content;
    width: 100%;
}
input[type="tel"] {
   background: transparent;
    border: none;
    color:#A1A1A1;
}
.inputStyle {
    background-color: rgba(177, 203, 207, 0.28);
    color: white;
    border-radius: 29px;
    outline: none;
    border: none;
    padding: 15px 20px;
}

.inputStyle::placeholder {
    color: #edeff0;
}

.inputStyle:focus {
    border: none;
    outline: none;
    color: #edeff0;
}
input[type="tel"]:focus {
    border: none;
    outline: none;
}
button {
    border: none;
    outline: none;
    background-color: transparent;
    margin-block:10px;
}
.buttonStyle {
    color: #005662;
    background-color:white;
    border-radius: 29px;
    font-size: x-large;
    width: 100%;
    height: min-content;
    padding-block: 5px;
    /*font-weight: bold;*/
    margin-top: 20px;
}
.buttonStyleDark {
    color: white;
    background-color:#005662;
    border-radius: 29px;
    font-size: x-large;
    width: 100%;
    height: min-content;
    padding-block: 5px;
    /*font-weight: bold;*/
    margin-top: 20px;
}
.google-login{
    color: #005662;
    background-color:white;
    border-radius: 29px;
    font-size: x-large;
    width: 100%;
    height: min-content;
    padding-block: 5px;
    /*font-weight: bold;*/
    margin-top: 20px;
}
.apple-login{
    color: #005662;
    background-color:black;
    border-radius: 29px;
    font-size: x-large;
    width: 100%;
    height: min-content;
    padding-block: 5px;
    /*font-weight: bold;*/
    margin-top: 20px;
}
.registerContainer {
    background-color: rgba(0, 53, 61, 0.94);
    width: 100%;
    color: white;
}
.registerButton {
    color: white;
    background-color: #005662;
    border-radius: 29px;
    font-size: x-large;
    height: min-content;
    padding-block: 5px;
    width: 100%;
    margin: 10px;
    /*font-weight: bold;*/
}
a {
    color: #b1cbcf;
    font-size: small;
}
.error {
    color: white;
    margin: 5px;
    margin-left:15px;
    font-size: small;
}

.inputStyleDark {
    background-color: #005662AA;
    color: white;
    border-radius: 29px;
    outline: none;
    border: none;
    padding: 15px 20px;
}
.placeholder-color::placeholder {
   color:#005F95;
}
.red-placeholder input::placeholder {
    color: #005F95;
}
.inputStyleDark::placeholder {
    color: #edeff0;
}

.inputStyleDark:focus {
    border: none;
    outline: none;
}


@media (max-width: 767px) {
    .inputContainers{
        grid-template-columns:1fr;
    }
    .inputContainers :nth-child(1) {
        grid-column-start: 1;
    }
}
