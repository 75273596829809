.filter{
    background-color: #bad1d4;
    color:white ;
    padding: 2px 10px;
    border-radius: 10px;
    cursor: pointer;
}

.filter-active{
    background-color: #005662 ;
    padding: 2px 10px;
    color:white;
}
.beach-image{
    border-radius: 20px;
    height: 250px;
    width: 100%;
}
.discover-all-container{
    padding-left: 100px;
    padding-right: 100px;
}
.filter-card{
    box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.1);
    border: none;
    background: #ebf2f3;
}
.no-borders-white{
    border-radius: 20px;
    padding: 10px 10px;
    background-color: white;
    border:none
}
.no-borders-white:focus-visible {
    border: none !important
}
.no-borders-white::placeholder {
    color:#edeff0;
}

.no-borders-white:focus {
    border: none;
    outline: none;
}
.filter-text{
    color: #6699a1;
}

@media (max-width: 767px) {
    .discover-all-container{
        padding-left: 0px;
        padding-right: 0px;
    }
}
