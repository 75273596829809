header {
    min-height: 150px;
    top: 0;
    left: 0;
    width: 100%;
    position: absolute;
    z-index: 1;
}


.background-header-container {
    background-color: #0006;
    min-height: 150px;
    width: 100%;
}

.logged-in-avatar {
    background-color: white;
    border-radius: 50%;
    height: 30px;
    width: 30px;
    color: #005662;
    cursor: pointer;
    display: grid;
    justify-content: center;
    align-items: center;
    font-size: 13px;
    text-transform: uppercase; /* Transforms all characters to uppercase */
}
.search-field{
    border-radius: 20px;
    padding: 10px 10px;
    background-color: #FFF4;
}

.no-borders{
    border: none;
    background:transparent;
    color:white
}
.no-borders:focus-visible {
    border: none !important
}

.no-borders:focus {
    border: none;
    outline: none;
}

@media (max-width: 767px) {
    .main-logo{
        width: 70%;
    }
}

