.editStyle {
    color: #005662;
    font-size: xx-large;
    /*font-weight: bolder;*/
    letter-spacing: 1px;
}
.editContainer {
    display:grid;
    grid-template-columns: 1fr 1fr ;
    gap:150px;
    /* border: 1px solid rgba(112, 112, 112,0.12); */
}
.infoContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    margin-block: 20px;

}

@media (max-width: 767px) {
    .editContainer {
        display:grid;
        grid-template-columns: 1fr;
        gap:10px;
    }
}
.infoStyle {
    background-color: rgba(177, 203, 207,0.95);
    color: white;
    height: 50px;
    border-radius: 29px;
    outline: none;
    border: none;
    padding: 10px;
    padding-left: 20px;
}
.infoStyleDisabled {
    background-color: #cfcfcf;
    color: white;
    height: 50px;
    border-radius: 29px;
    outline: none;
    border: none;
    padding: 10px;
    padding-left: 20px;
}
.error {
    color: red;
    margin: 5px;
    margin-left:15px;
    font-size: small;
    /*font-weight: bold;*/
}
.saveButton {
    color:white ;
    background-color: #005662;
    border-radius: 29px;
    font-size: large;
    /*font-weight: bold;*/
    width: 100%;
    height: min-content;
    padding-block: 10px;
    margin-top: 60px;
}
::placeholder {
    color:#edeff0;
}
a {
    color: #005662;
    margin: 5px;
    margin-left:15px;
    font-size: small;
    /*font-weight: bold;*/
}

.sort-style{
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
    border-radius: 25px;
    cursor: pointer;
    color:#005662
}
.add-reservation-style{
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
    border-radius: 25px;
    cursor: pointer;
    background: #005662;
    color:white
}
.add-note{
    color:#005662;
    cursor: pointer;
}
.paid{
    background: green;
    width: fit-content;
    padding-inline: 10px;
    border-radius: 20px;
    color:white
}
.not-paid{
    background: orange;
    width: fit-content;
    padding-inline: 10px;
    border-radius: 20px;
    color:white

}
