.rounded-point-active{
    border-radius: 50%;
    background: #005662;
    height: 10px;
    width: 10px;
}
.breadcrumb-text-active{
    cursor: pointer;
    color:#005662;
}

.rounded-point-disabled{
    border-radius: 50%;
    background: #c7dadd;
    height: 10px;
    width: 10px;
}
.breadcrumb-text-disabled{
    cursor: pointer;
    color: #c7dadd;
}