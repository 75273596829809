.header-text{
    color: #005662 ;
}
.popular-card {
    background-size: cover;
    width: 100%;
    background-position: center;
    position: relative;
    height: 400px;
    border-radius: 20px;
    background-color: #f0f0f0;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
}

.middle-card {
    height: 500px;
    transform: translateY(-50px); /* Adjust the value as needed to control the pop-up effect */
    z-index: 1;
}

.tag{
    background-color: #005662 ;
    padding: 2px 10px;
    color:white;
    border-radius: 13px;
    width: fit-content;
}

.beach-information{
    bottom: 0;
    position: absolute;
}

.popular-beach-information{
    color:white;
}
.background-popular-overlay{
    justify-content: center;
    padding: 20px;
    top: 0;
    left: 0;
    border-radius: 20px;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2); /* Adjust the alpha (4th value) to control darkness */
}

.searched-user{
    background: white;
    box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    padding: 7px;
    padding-left: 20px;
    cursor: pointer;
    display: grid;
    align-items: center;
}
.searched-user-selected{
    background: #6699a1;
    color:white;
}
