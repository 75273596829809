.fixed-sidebar {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    background-color: #f8f9fa;
    overflow-y: scroll; /* Enable vertical scrolling */
}

.blue-bg{
 background-color: #e5eeef;
}
.dark-blue-bg{
    background-color: #005662;
}
.burger{
    background-color: #b1cbcf;
    height: 100vh;
    display: grid;
    justify-content: center;
    align-items: center;
}
.items{
    background-color: #e5eeef ;
}
.fixed-button{
    bottom: 0;
    position: absolute;
}

.fixed-height{
    overflow: scroll;
    max-height: 90vh;
}

.reserved-circle{
    height: 10px;
    width: 10px ;
    border-radius: 50%;
    background: red;
}
.checked-in-circle{
    height: 10px;
    width: 10px ;
    border-radius: 50%;
    background: #b8fad4;
}
.confirmed-circle{
    height: 10px;
    width: 10px ;
    border-radius: 50%;
    background: #FFC829;
}
.canceled-circle{
    height: 10px;
    width: 10px ;
    border-radius: 50%;
    background: #a6aba7;
}

.displayed-user-info{
    color:#005662;
    /*font-weight: lighter;*/
    font-size: 14px;
}
.word-break{
    word-wrap: break-word;
    overflow-x: hidden;
}
.reservation{
    cursor: pointer;
    border-top: 1px solid #e6e6e6;
    border-bottom: 1px solid #e6e6e6;
}
.reservation:hover{
    background: lightgray;

}
.stats{
    color:white;
    font-size: 12px;
}
.stats-number{
    color:white;
    /*font-weight: bold;*/
    font-size: 22px;
}
.item-style{
    color:#005662;
    /*font-weight: lighter;*/
    font-size: 14px;
}
.dashboard-filter{
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
    border-radius: 20px;
    cursor: pointer;
    align-items: center;
}
