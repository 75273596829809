.testimonial-stepper{
    align-items: center  !important;
    justify-content: center  !important;

    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}
.slider-image{
    width: 100%;
    height: 500px;
    user-select: none;
}

.light-header-background{
    background-color: #000000;
}
.background-image-container {
    background-size: cover;
    user-select: none;
    width: 100%;
    background-position: center;
    height: 850px;
}
.background-overlay {
    display: grid;
    align-items: center;
    color:white;
    justify-content: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2); /* Adjust the alpha (4th value) to control darkness */
}
.testimonial-stepper{
    background-color: transparent !important;
    position: absolute;
    right: 50%;
    translate: 50% -100%;
}

.MuiMobileStepper-dot{
    background-color:gray !important
}
.MuiMobileStepper-dotActive{
    background-color: white !important;
}
.MuiMobileStepper-dots{
    gap: 60px !important;
}

@media (max-width: 767px) {
    .background-image-container {
        height: 750px;
    }
}
