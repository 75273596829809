
.grid-line-horiz {
    position: absolute;
    width: 100%;
    height: 1px;
    border: 0.5px dashed #a6aba7;
    z-index: 10;
}

.grid-line-vertical {
    position: absolute;
    height: 1px;
    z-index: 10;
    border: 0.5px dashed #a6aba7;
}


.vertical-line {
    left: 0;
    height: 100%;

}
