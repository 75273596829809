.google-maps-indicator{
    height:130px;
    width:100%
}

@media (max-width: 767px) {
    .discover-all-container{
        padding-left: 0px;
        padding-right: 0px;
    }
}
