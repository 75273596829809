.button-reservation {
    color: white;
    border-radius: 33px;
    font-size: 14px;
    /*font-weight: bold;*/
    height: min-content;
    padding-block: 10px;
    cursor: pointer;
}

.sideBar {
    position: fixed;
    padding: 20px 20px;
    top: 0;
    bottom: 0;
    right: 0;
    width: 35%;
    background-color: #EBF2F3;
    color: #005662;
    z-index: 1000;
    overflow-y: auto;
}

.bedImage {
    width: 15px;
    height: 30px;
}

.payStyle {
    color: white;
    background-color: #005662;
    border-radius: 30px;
    font-size: large;
    /*font-weight: bold;*/
    width: 100%;
    height: min-content;
    padding-block: 10px;
}

.bag-beach-image {
    border-radius: 15px
}

@media (max-width: 767px) {
    .sideBar {
        width: 85%;
        padding: 0;
    }

}
