.bag-card{
    background: #e4eef0 ;
    border-radius: 10px;
    padding-bottom: 10px;
}

.bag-card-text{
    color: white;
    font-size: 23px;
    /*font-weight: bold;*/
}
.bag-card-warning{
    color: red;
    font-size: 17px;
    /*font-weight: bold;*/
}
.card-bag-header{
    background:#005662 ;
    border-radius: 10px;
}
.card-beach-image{
    border-radius: 10px;
    height: 120px;
    width: 200px;
}
.total-card{
    background:#005662;
    color: white;
    border-radius: 10px;

}
.date-text{
    background: white;
    border-radius: 20px;
    color: #005662;
    font-size: 20px;
    padding-block: 5px;
}
.divider{
    border-bottom: 1px solid #b4d1d5;
}
.split-bill{
 color:#397c85
}
